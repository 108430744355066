(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name components.filter:array
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('components')
    .filter('array', array);

  function array($filter) {
    return function (input, term, fields = [], operator = false) {
      if (!term.length) {
        return input;
      }
      if (!fields) {
        return $filter('filter')(input, term);
      }
      let filtered = [], valid;

      angular.forEach(input, function (value) {
        valid = !operator;
        for (let j = 0; j < term.length; j++) {
          for (let i = 0; i < fields.length; i++) {
            let index = value[fields[i]].indexOf(term[j]);
            if (operator && index >= 0) {
              valid = true; break;
            } else if (!operator && index < 0) {
              valid = false; break;
            }
          }
        }
        if (valid) {
          this.push(value);
        }
      }, filtered);

      return filtered;
    };
  }
}());
